import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
// import Optin from "./components/Optin/Optin";

import "./styles.css";

function App() {
  return (
    <div className="App">
    
      <div className="container">
      <div className="image-container">
            <img src="https://d.thakiratalsharq.com/assets/images/header-logo.png" alt="Background" className="fixed-image" />
        </div>
        <h1>
        {/* thakiratalsharq.com */}
          <br />
          Coming Soon
        </h1>
        {/* <Timer /> */}
        {/* <Optin /> */}
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
